import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { app_api } from '../../../utils/endpoints/app_api';
import { debounce } from '../../../helpers/helpers';
import { get_delete_request } from '../../../helpers/request';

export default function CountryComponent({
  options,
  getEndpoint,
  searchEndpoint,
  text,
  fieldName,
  fieldName2,
  getValueFunc,
  setValue,
  isReq,
  errors,
  isNotPaginated,
  loadReq = true,
  defaultValue
}) {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authData);

  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [valueList, setValueList] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [totalValueRecords, setTotalValueRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const scrollContainerRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [isOpenedFirstTime, setIsOpenedFirstTime] = useState(false);
  const [validationError, setValidationError] = useState('');

  const { register, formState: { errors: formErrors } } = useForm();

  useEffect(() => {
    // If there is a defaultValue, fetch its details
    const fetchDefaultCountry = async () => {
      if (defaultValue) {
        // API call to get the default country by its ID
        const res = await get_delete_request('get', token, `${getEndpoint}/${defaultValue}`, {}, dispatch, "", "", "");
        if (res !== undefined && res.status < 400) {
          const country = res?.data?.data;
          setValue(fieldName2, country.name);
          setValue(fieldName, country.id);
          setSearchVal(country.name);
        }
      }
    };

    fetchDefaultCountry();
  }, [defaultValue]);

  useEffect(() => {
    if (loadReq === false) {
      if (isOpenedFirstTime) {
        get_values();
      }
    } else {
      get_values();
    }
  }, [isOpenedFirstTime]);



  // useEffect(() => {
  //   if (defaultValue) {
  //     const defaultOption = valueList.find((option) => option.id === defaultValue);
  //     if (defaultOption) {
  //       setValue(fieldName2, defaultOption.name);
  //       setValue(fieldName, defaultOption.id);
  //       setSearchVal(defaultOption.name);
  //     }
  //   }
  // }, [defaultValue, valueList]);

  const toggleDropdown = () => {
    if (loadReq === false) {
      if (isOpenedFirstTime === false) {
        setIsOpenedFirstTime(true);
      }
    }
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.classList.contains('select-inp')
    ) {
      setIsOpen(false);
    }
  };

  const selectOption = (textValue, textId) => {
    setValue(fieldName2, textValue);
    setValue(fieldName, textId);
    setSearchVal(textValue);
    setIsOpen(false);
  };

  const handleScroll = () => {
    const element = scrollContainerRef.current;
    const distanceToBottom = element.scrollHeight - element.scrollTop - element.clientHeight;
    const atBottom = distanceToBottom < 1;
    setIsAtBottom(atBottom);
  };

  useEffect(() => {
    if (!getValueFunc(fieldName2)) {
      setValue(fieldName2, text);
    }
    document.getElementById('body').addEventListener('click', handleClickOutside);
    return () => {
      document.getElementById('body')?.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isAtBottom && (Number(totalValueRecords) > valueList.length)) {
      if (!isSearch) {
        get_values();
      }
      if (isSearch) {
        search_values(searchVal);
      }
    }
  }, [isAtBottom]);

  const get_values = async () => {
    let url = isNotPaginated ? `${getEndpoint}` : `${getEndpoint}?page=${pageNo}&pageSize=10`;
    const res = await get_delete_request('get', token, url, {}, dispatch, "", "", "");
    if (res !== undefined && res.status < 400) {
      if (isNotPaginated) {
        setValueList([...valueList, ...res?.data?.data]);
      } else {
        setValueList([...valueList, ...res?.data?.data?.data]);
        setTotalValueRecords(res?.data?.data?.meta.total);
        setPageNo(pageNo + 1);
        setListLoading(false);
      }
    }
  };

  const search_values = async (query) => {
    if (searchEndpoint) {
      setIsSearch(true);
      setListLoading(true);
      setValueList([]);
      setPageNo(1);

      const res = await get_delete_request('get', token, `${searchEndpoint}?q=${query}&page=${pageNo}&pageSize=10`, {}, dispatch, "", "", "");

      if (res !== undefined && res.status < 400) {

        if (res?.data?.data?.data.length === 0) {
          //alert("No countryId provided");
          setValidationError('Canot update..Please select a country from the list');
          //return false;
        } else {
          setValueList(res?.data?.data?.data);
          setTotalValueRecords(res?.data?.data?.meta.total);
          setListLoading(false);
        }
      }
    } else {
      let filteredList = valueList.filter((item) =>
        item.text.toLowerCase().includes(query.toLowerCase())
      );
      setValueList(filteredList);
    }
  };

  const handleInputChange = (event) => {

    const query = event.target.value;
    const key = event.nativeEvent.inputType;
    if (key === 'deleteContentBackward') {
      search_values("");
      setSearchVal("");
      setValidationError('');
    } else {
      setSearchVal(query);
      setValue(fieldName2, query); // Update React Hook Form value
      search_values(query);
      if (isReq && query === '') {
        setValidationError('*this field is required');
      } else {
        setValidationError('');
      }

    }

  };

  return (
    <div className='position-relative'>
      <div className="input-group select-inp">
        <input
          ref={inputRef}
          {...register(fieldName2, {
            required: isReq ? '*this field is required' : false
          })}
          onClick={toggleDropdown}
          onChange={handleInputChange}
          value={searchVal}
          style={{ borderRight: '0px solid transparent' }}
          type='text'
          autoComplete='off'
          className="form-control select-inp"
          id={fieldName2}
        />
      </div>
      {validationError &&
        <div className="text-danger small mt-1">
          {validationError}
        </div>
      }
      {formErrors[fieldName2] &&
        <div className="text-danger small mt-1">
          {formErrors[fieldName2]?.message}
        </div>
      }
      {isOpen &&
        <div ref={dropdownRef} style={{ width: '100%', zIndex: '20' }} className='select-inp position-absolute mt-2 px-0 card border-0 col-12 col-md-10 col-xl-8'>
          <div ref={scrollContainerRef} onScroll={handleScroll} style={{ overflowY: 'auto', maxHeight: '250px' }} className='bg-white mt-1 fs-12 border custom-list-group list-group list-group-country'>
            <a className="list-group-item custom-list list-group-item-action disabled p-1 ps-3 overflow-visible">
              {text}
            </a>
            {valueList.map((option) => (
              <a
                key={option.id}
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.preventDefault();
                  selectOption(option.name, option.id);
                }}
                className="list-group-item select-inp custom-list list-group-item-action p-1 ps-3 overflow-visible"
              >
                {option.name}
              </a>
            ))}
            {listLoading &&
              <div style={{ fontSize: '1rem' }} className='text-center'><i className='fa fa-refresh fa-spin'></i></div>
            }
          </div>
        </div>
      }
    </div>
  );
}
