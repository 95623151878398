import React, { useEffect, useState } from 'react'
import AppWrapper from './AppWrapper'
import SideBar from './customer/SideBar'
// import NavbarLayout from './NavbarLayout';
import NavbarLayout from './NavbarLayout'
import { ToastContainer, toast } from 'react-toastify'
import '../../assets/css/customer_styles.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  clearOnReload,
  logoutCustomerUser,
  setToggleSidebar,
} from '../../config/redux/slice/customerSlice'
import loading_gif from '../../assets/images/logo-loading-gif.gif'
import {
  logoutUser,
  onGetUser,
  setPageLoading,
} from '../../config/redux/slice/authSlice'
import {
  get_delete_request,
  post_put_patch_request,
} from '../../helpers/request'
import { app_api } from '../../utils/endpoints/app_api'
import {
  logoutAppUser,
  setActiveSidebar,
  setShouldModifyCart,
} from '../../config/redux/slice/appSlice'
import { logoutAdminUser } from '../../config/redux/slice/adminSlice'
import BreadCrumb from './customer/BreadCrumb'
import SuccessModal from '../pages/customer/sell_events/SuccessModal'
import CookieNotice from '../components/CookieNotice'
import VerifyPhoneNumber from '../components/VerifyPhoneNumber'
import PhoneNumberVerificationSuccess from '../components/PhoneNumberVerificationSuccess'
import WelcomeMessage from '../components/WelcomeMessage'
import AlertPopup from '../../../src/app/components/alertpopup'
export default function CustomerWrapper({
  children,
  heading,
  isSidebarReq = true,
}) {
  // const [toggleValue, setToggleValue] = useState(false)
  const dispatch = useDispatch()
  const {
    cartManagement: { cartData },
  } = useSelector((state) => state.app)

  // const [pageLoading, setPageLoading] = useState(false)

  // useEffect(()=>{
  //   if(shouldModifyCart){
  //     addToCart()
  //   }
  // }, [shouldModifyCart])

  const {
    token,
    loading,
    errorMsg,
    pageLoading,
    userInfoExists,
    showPhoneNumberVerificationModal: { show, type, message },
  } = useSelector((state) => state.authData)

  const {
    isSidebarOpen,
    customerLoading,
    customerErrorMsg,
    customerSuccessMsg,
    customerSuccessModal,
  } = useSelector((state) => state.customer)

  useEffect(() => {
    dispatch(clearOnReload())
  }, [])

  useEffect(() => {
    let mainPath = ''

    let path = window.location.pathname

    let fullPathArray = path.split('/')

    if (fullPathArray.length <= 2) {
      mainPath = path
    } else {
      mainPath = `/${fullPathArray[1]}/${fullPathArray[2]}`
    }

    // let fullPathArray = path.split("/")
    // if(fullPathArray.length <= 2){
    //   mainPath = `/${fullPathArray[1]}`
    // }else{
    //   mainPath = user_role.toLowerCase() == 'TRANSFEREE_USER' ? `/${fullPathArray[1]}` : `/${fullPathArray[1]}/${fullPathArray[2]}`
    // }
    dispatch(
      setActiveSidebar({
        mainPath: mainPath,
      })
    )
  }, [])

  useEffect(() => {
    if (customerSuccessMsg) {
      let msg
      if (Array.isArray(customerSuccessMsg)) {
        msg =
          customerSuccessMsg[0].toLowerCase().charAt(0).toUpperCase() +
          customerSuccessMsg[0].slice(1)
      } else {
        msg =
          customerSuccessMsg.toLowerCase().charAt(0).toUpperCase() +
          customerSuccessMsg.slice(1)
      }

      dispatch(clearAll())

      showToastMessage(msg, false)
    }

    if (customerErrorMsg) {
      let err
      if (Array.isArray(customerErrorMsg)) {
        err =
          customerErrorMsg[0].toLowerCase().charAt(0).toUpperCase() +
          customerErrorMsg[0].slice(1)
      } else {
        err =
          customerErrorMsg.toLowerCase().charAt(0).toUpperCase() +
          customerErrorMsg.slice(1)
      }
      dispatch(clearAll())
      if (err?.toLowerCase() == 'unauthorized') {
        dispatch(logoutUser())
        dispatch(logoutAdminUser())
        dispatch(logoutAppUser())
        dispatch(logoutCustomerUser())
        err = null
      } else {
        showToastMessage(err, true)
      }

      // showToastMessage(err, true)

      // showToastMessage(err, true)
    }
  }, [customerSuccessMsg, customerErrorMsg])

  useEffect(() => {
    document.body.classList.add('has-admin')

    // Clean up the class on unmount (optional)
    return () => {
      if (document.querySelector('.has-admin')) {
        document.body.classList.remove('has-admin')
      }
    }
  }, [])

  // useEffect(()=>{
  //    const timer = setTimeout(() => {
  //         if(userInfoExists){
  //             setPageLoading({loading: false})
  //         }
  //     }, 4000);
  // },[])
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const toggleSidebar = () => {
    dispatch(setToggleSidebar())
  }

  // const get_auth_user  = async () => {
  //   setPageLoading(true)
  //     dispatch(clearAll({}))
  //     const res = await get_delete_request(
  //       'get',
  //         token,
  //         `${app_api.get_user}`,
  //         {},
  //         dispatch,
  //         "",
  //         onGetUser,
  //         ""
  //     )
  // }
  const handleShowPopup = () => {
    //debugger
  console.log('Button clicked, showing popup');
  setShowPopup(true);
};

const handleHidePopup = () => {
  console.log('Popup closed');
  setShowPopup(false);
};
const showToastMessage = (msg, isError) => {
  setIsError(isError);  // Set the error flag
  if (isError) {
    setPopupMessage(msg);
    handleShowPopup();
  } else {
      setPopupMessage(msg);
      handleShowPopup();
  }
};

  const addToCart = async () => {
    dispatch(clearAll({}))
    let ticketIds = []
    cartData.forEach((listingOption) => {
      listingOption.forEach((ticketOption) => {
        ticketIds = [...ticketIds, ticketOption.id]
      })
    })
    let data = { ticketIds }
    // dispatch(setFormData({data, type: 'listing'}))
    const res = await post_put_patch_request(
      'patch',
      token,
      `${app_api.add_to_cart}`,
      data,
      dispatch,
      '',
      '',
      ''
    )
    if (res !== undefined && res.status < 400) {
      dispatch(setShouldModifyCart(false))
    } else {
      dispatch(setShouldModifyCart(false))
    }
  }

  return (
    <div>
      <NavbarLayout />
      <AlertPopup show={showPopup} handleHide={handleHidePopup} message={popupMessage} isError={isError}/>
      <ToastContainer />
      {customerSuccessModal && (
        <SuccessModal successMsg={customerSuccessModal} />
      )}
       {show && type == 'success' && (
        <PhoneNumberVerificationSuccess successMsg={message} />
      )}
      {show && type == 'welcome' && <WelcomeMessage />}
      {show && type == 'verify' && <VerifyPhoneNumber />}
      
      {isSidebarReq ? (
        <div className="position-relative">
          <button
            style={{ zIndex: 1059 }}
            onClick={toggleSidebar}
            className="nav-button d-block d-md-none position-absolute btn-lg btn btn-accent rounded-circle"
          >
            {isSidebarOpen ? (
              <i className="fa fa-angle-left fs-4 fw-bold"></i>
            ) : (
              <i className="fa fa-angle-right fs-4 fw-bold"></i>
            )}
          </button>
          {/* <div className='d-none d-lg-block'> */}
          <SideBar />
          {/* </div> */}
          <div className="main-content">
            <BreadCrumb breadcrumb_name={heading} />
            <div className="py-4 px-2 px-md-3 px-xl-5">{children}</div>
            {/* <h3 className='fw-bold'>{heading}</h3> */}
          </div>
        </div>
      ) : (
        <div style={{ paddingTop: '66px' }}>{children}</div>
      )}
      <CookieNotice />
    </div>
  )
}
