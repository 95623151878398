// export const formatErrorMessage = (error) => {
//     return error.response !== undefined ?
//         error.response.data.error !== undefined ?
//             error.response.data.error : error.response.data.message
//         :
//         error.message
// }

import moment from 'moment'

export const formatErrorMessage = (error) => {
  return error.response !== undefined
    ? error.response.data.message !== undefined
      ? error.response.data.message
      : error.response.data.error
    : error.message
}

export const isAuthenticated = () => {
  const token = localStorage.getItem('notap_user')
  if (token) {
    return true
  } else {
    return false
  }
}

export const transformFirstLetter = (stringValue) => {
  return (
    stringValue.toLowerCase().charAt(0).toUpperCase() + stringValue.slice(1)
  )
}

export const getListOfYears = () => {
  let start = new Date('01 January 2023')
  let from = start.getFullYear()

  let endYear = moment().year()

  let end = new Date('01 January ' + endYear)
  let to = end.getFullYear()
  let arr = []

  for (let i = from; i <= to; i++) arr.unshift(i)

  return arr
}

export const getExtension = (url) => {
  if (url && url !== '' && url !== 'N/A') {
    let urlArray = url.split('.')

    let urlArrayLength = urlArray.length

    let lastItemIndex = urlArrayLength - 1

    let extension = urlArray[lastItemIndex]

    return extension.toLowerCase()
  } else {
    let extension = 'empty'

    return extension
  }
}

export const getFilename = (url) => {
  if (url && url !== '' && url !== 'N/A') {
    let urlArray = url.split('\\')

    let urlArrayLength = urlArray.length

    let lastItemIndex = urlArrayLength - 1

    let extension = urlArray[lastItemIndex]

    return extension.toLowerCase()
  } else {
    let extension = 'empty'

    return extension
  }
}

export function truncateMiddle(text, maxLength) {
  if (text.length <= maxLength) {
    return text // Return the original text if it's shorter than or equal to maxLength
  } else {
    const ellipsisLength = 3 // Length of "..."
    const portionLength = Math.floor((maxLength - ellipsisLength) / 2)

    const startPortion = text.substring(0, portionLength)
    const endPortion = text.substring(text.length - portionLength)

    return `${startPortion}....${endPortion}`
  }
}

export const formatNumber = (originalValue) => {
  let formattedAmount
  if (originalValue) {
    formattedAmount = Number(originalValue).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  } else if (originalValue === 0) {
    return 0.0
  } else {
    formattedAmount = ''
  }
  return formattedAmount
}

export const debounce = (func, delay) => {
  let timeoutId
  return () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func()
    }, delay)
  }
}

export const convertToDateTime = (date, time) => {
  const dateTimeString = `${date}T${time}`
  const dateTime = moment(dateTimeString).format()

  return dateTime
}

export const transformTicketsList1 = (ticketsList, listingItem) => {
  if (ticketsList) {
    let arr = ticketsList.map((option) => {
      return option.row
    })
    const uniqueSet = new Set(arr);

    // Convert the Set back to an array
    const uniqueArray = Array.from(uniqueSet);
    let transformedTicketList = []
    uniqueArray.forEach((option, ind) => {
      let filteredTicket = ticketsList.filter((opt) => {
        return opt.row == option
      })

      let newTicketObj = {
        id: `${listingItem.id}custom${option}`,
        ticketIds: Array.from(filteredTicket, opt => opt.id),
        seatNos: Array.from(filteredTicket, opt => opt.seat),
        numberOfTickets: filteredTicket.length,
        price: filteredTicket[0]?.price,
        negotiatedPrice: filteredTicket[0]?.negotiatedPrice,
        detailsList: filteredTicket
      }
      transformedTicketList.push(newTicketObj)
    })
    return transformedTicketList
  } else {
    return []
  }
}


// xillidev ticketgrouping XDSD
export const transformTicketsList = (ticketsList, listingItem, arriveType, numberOfTickets, minPrice, maxPrice,role) => {
  //  debugger

  if (arriveType === "cart") {
    if (ticketsList) {
      let rows = Array.from(new Set(ticketsList.map(option => option.row)));
      return rows.map(option => {
        let filteredTickets = ticketsList.filter(opt => opt.row === option);
        return {
          id: `${listingItem.id}custom${option}`,
          ticketIds: filteredTickets.map(opt => opt.id),
          seatNos: filteredTickets.map(opt => opt.seat),
          numberOfTickets: filteredTickets.length,
          price: filteredTickets[0]?.price,
          negotiatedPrice: filteredTickets[0]?.negotiatedPrice,
          detailsList: filteredTickets
        };
      });
    }
    return [];
  } else {
    if (ticketsList) {
      let sortedTickets = [...ticketsList].sort((a, b) => parseInt(a.seat, 10) - parseInt(b.seat, 10));
      let transformedTicketList = [];

      if (listingItem?.sellingOption === 'ANY' || listingItem?.sellingOption === 'AVOID_LEAVING_SINGLE') {

        let progressiveList = [];
        sortedTickets.forEach((ticket, index) => {

          progressiveList.push(ticket);
          let totalTicketPrice = (listingItem.pricePerTicket * progressiveList.length);
          // if (totalTicketPrice >= minPrice && totalTicketPrice <= maxPrice) {
          let customId = `${listingItem.id}custom${index}`;
          transformedTicketList.push({
            id: customId,
            ticketIds: progressiveList.map(t => t.id),
            seatNos: progressiveList.map(t => t.seat),
            numberOfTickets: progressiveList.length,
            price: (listingItem.pricePerTicket),
            negotiatedPrice: progressiveList[0]?.negotiatedPrice,
            detailsList: [...progressiveList],
            pricePerTicketWithoutCommission :  (listingItem.pricePerTicketWithoutCommission)
          });
          // }

        });
        let z = [...transformedTicketList]


        if (listingItem.sellingOption === 'AVOID_LEAVING_SINGLE' && transformedTicketList.length > 1) {
          transformedTicketList.splice(transformedTicketList.length - 2, 1);
        }
        if (minPrice !== "" && maxPrice !== "") {
       
         if(role === "TRADER"){
          transformedTicketList = transformedTicketList.filter((obj) => (obj.pricePerTicketWithoutCommission >= minPrice && obj.pricePerTicketWithoutCommission <= maxPrice))
         }else{
          transformedTicketList = transformedTicketList.filter((obj) => (obj.price >= minPrice && obj.price <= maxPrice))
         }
        }



      } else {
        // debugger
        let rowGroups = {};

        if (minPrice !== "" && maxPrice !== "") {
             if(role === "TRADER"){
          let totalTicketPrice = listingItem.pricePerTicketWithoutCommission

          if (totalTicketPrice >= minPrice && totalTicketPrice <= maxPrice) {
            rowGroups = sortedTickets.reduce((acc, ticket) => {
              acc[ticket.row] = acc[ticket.row] || [];
              acc[ticket.row].push(ticket);
              return acc;
            }, {});
          }}
          else{
            let totalTicketPrice = listingItem.pricePerTicket 

          if (totalTicketPrice >= minPrice && totalTicketPrice <= maxPrice) {
            rowGroups = sortedTickets.reduce((acc, ticket) => {
              acc[ticket.row] = acc[ticket.row] || [];
              acc[ticket.row].push(ticket);
              return acc;
            }, {});
          }
          }
        } else {
          // If minPrice and maxPrice are both 0, process all tickets without filtering by price
          rowGroups = sortedTickets.reduce((acc, ticket) => {
            acc[ticket.row] = acc[ticket.row] || [];
            acc[ticket.row].push(ticket);
            return acc;
          }, {});
        }

        Object.values(rowGroups).forEach((group, index) => {
          transformedTicketList.push({
            id: `${listingItem.id}custom${index + 1}`,
            ticketIds: group.map(t => t.id),
            seatNos: group.map(t => t.seat),
            numberOfTickets: group.length,
            price: listingItem.pricePerTicket,
            pricePerTicketWithoutCommission :  (listingItem.pricePerTicketWithoutCommission),
            negotiatedPrice: group[0]?.negotiatedPrice,
            detailsList: group,
          });
        });

      }


      // let filteredList = (numberOfTickets === undefined || numberOfTickets === '')
      //   ? transformedTicketList
      //   : transformedTicketList.filter(item => item.numberOfTickets === numberOfTickets);
      // console.log("reansssssssss ....a", filteredList);
      // return filteredList;

      let filteredList = transformedTicketList;

      if (numberOfTickets === undefined || numberOfTickets === '') {
        // Do nothing, use the entire transformedTicketList
      } else if (numberOfTickets == 5) {
        filteredList = transformedTicketList.filter(item => item.numberOfTickets >= numberOfTickets);
      } else {
        filteredList = transformedTicketList.filter(item => item.numberOfTickets === numberOfTickets);
      }


      return filteredList;
    } else {
      return [];
    }
  }
}




// xillidev ticketgrouping XDSD

export const transformSellOption = (string) => {
  const sellOption = {
    FULL: 'Full',
    ANY: 'Any',
    AVOID_LEAVING_SINGLE: 'Avoid leaving single',
  }
  if (string) {
    return sellOption[string]
  } else {
    return 'N/A'
  }
}

export const buildAddress = (address) => {
  let finalAddress
  for (let key in address) {
    if (address[key]) {
      finalAddress = finalAddress
        ? `${finalAddress}, ${address[key]}`
        : `${address[key]}`
    }
  }
  return finalAddress ?? 'N/A'
}

export const setCartNo = (data) => {
  let cartNo = 0
  data.forEach((opt) => {
    if (opt.sellingOption === 'ANY') {
      opt.Tickets.forEach((ticketOpt) => {
        cartNo += 1
      })
    } else {
      let arr = opt.Tickets.map((option) => {
        return option.row
      })
      const uniqueSet = new Set(arr)

      // Convert the Set back to an array
      const uniqueArray = Array.from(uniqueSet)
      uniqueArray.forEach((ticketOpt) => {
        cartNo += 1
      })
    }
    // else{
    //   cartNo += 1
    // }
  })
  return cartNo
}
