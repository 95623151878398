import React , {useEffect} from 'react'
import primetic_logo from '../../../../assets/images/primetic_logo.png'
import userIcon from '../../../../assets/images/userIcon.png'
import default_img from '../../../../assets/images/default-img.jpeg'
import { navigationStructure } from '../../../../utils/nav_structure'
import { Link, useNavigate } from 'react-router-dom'
import { app_api } from '../../../../utils/endpoints/app_api'
import {app_urls} from '../../../../utils/urls/app_urls'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../../../config/redux/slice/authSlice'
import { logoutCustomerUser } from '../../../../config/redux/slice/customerSlice'
import {
  logoutAppUser,
  setBreadCrumbName,
  showCartModalFunc,
} from '../../../../config/redux/slice/appSlice'
import { system_user_roles } from '../../../../constants/english'
import { logoutAdminUser } from '../../../../config/redux/slice/adminSlice'
import EventHeaderInfo from './EventHeaderInfo'
import SearchComponent from '../../../components/SearchComponent';
import moment from 'moment/moment'



export default function NavbarLayout({ eventObj }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { userData, token } = useSelector((state) => state.authData)

  // const {
  //   eventManagement:{eventDetails}
  // } = useSelector((state) => state.app)

  const {
    isActive,
    cartManagement: { cartData, cartNumber },
  } = useSelector((state) => state.app)

  const logout = (e) => {
    e.preventDefault()
    dispatch(logoutUser())
    dispatch(logoutCustomerUser())
    dispatch(logoutAppUser())
    dispatch(logoutAdminUser())
  }


  const handleRegisterToSell = () => {
    navigate(app_urls.sellers) // Use the appropriate URL
  }

  return (
    
    <div className="row header-wrapper">
      <div className="col-md-7 d-flex justify-content-between align-items-center">
        <div className="col-md-4 header-width-logo">
          <Link className="navbar-brand" to={app_urls.home}>
            <img className="app-logo" src={primetic_logo} alt="primetic logo" />
          </Link>
        </div>

        <div className="col-md-8 header-text-sec header-width-textLeft">
          <h3 className='main-headertext'>{eventObj?.name}</h3>
          <p className="sub-headertext">{moment(eventObj?.date).format('dddd MM MMMM, hh:mm A')}. {eventObj?.Venue?.name}
           </p>
        </div>
      </div>
      <div className="col-md-5 header-right-div">
        <div className='col-12 col-md-12 col-lg-8 header-right-sec'>
          <SearchComponent landingPage={"buy"} showPage={true}/>
        </div>
      
      <div className='col-12 col-md-12 col-lg-4 header-btn-sec'>
        <button className='btn-header-sec bg-btn-header' onClick={handleRegisterToSell}>Sell Tickets</button>
        
        {token && token != '' && userData?.email != '' ? (
          <ul
            style={{ width: '60px', flexBasis: 'auto' }}
            className="ms-lg-0 p-0 m-0 order-2 order-lg-3"
          >
            <li className="dropstart user-menu">
              <button
                className="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
              >
                <img
                  crossOrigin="anonymous"
                  src={
                    !userData?.avatar || userData.avatar == ''
                      ? default_img
                      : userData?.avatar
                  }
                  className="user-image rounded-circle"
                  alt="User"
                />
              </button>
              <ul style={{ fontSize: '0.8rem' }} className="dropdown-menu">
                {system_user_roles.includes(userData.user_role.toLowerCase()) ==
                false ? (
                  <>
                    {/* <li>
                          <Link className="dropdown-item" to={app_urls.dashboard}>
                            <i className="mdi mdi-view-dashboard"></i>
                            <span className="nav-text">Dashboard</span>
                          </Link>
                        </li> */}

                    <li>
                      <Link className="dropdown-item" to={app_urls.dashboard}>
                        <i className="mdi mdi-account-outline"></i>
                        <span className="nav-text">Dashboard</span>
                      </Link>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link
                      onClick={() => {
                        dispatch(
                          setBreadCrumbName([
                            {
                              id: 1,
                              name: 'Dashboard',
                              url: app_urls.admin_dashboard,
                            },
                          ])
                        )
                      }}
                      className="dropdown-item"
                      to={app_urls.admin_dashboard}
                    >
                      <i className="mdi mdi-account-outline"></i>
                      <span className="nav-text">Dashboard</span>
                    </Link>
                  </li>
                )}
                <li className="dropdown-footer">
                  <a
                    onClick={(e) => {
                      logout(e)
                    }}
                    className="dropdown-footer-item"
                    href="#"
                  >
                    {' '}
                    <i className="mdi mdi-logout"></i> Log Out{' '}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        ) :(<button className='btn-header-sec'   onClick={() => {
          navigate(app_urls.login)
        }}>Login <img className="ml-2" src={userIcon} alt="user" width={15} style={{marginLeft:"4px"}}/></button>

        )}
        
      </div>
      </div>

    </div>
  )
}
