import React from 'react'
import ModalLayout from '../layout/ModalLayout'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './Spinner'
import {
  clearAll,
  setErrorMessage,
  showAddressVerificationModalFunc
} from '../../config/redux/slice/authSlice'
import { useNavigate } from 'react-router-dom' // Import useNavigate
import AuthAlert from './AuthAlert'

export default function VerifyAddress() {
  const dispatch = useDispatch()
  const navigate = useNavigate() // Create navigate function

  const { loading, errorMsg, token } = useSelector((state) => state.authData)

  const updateAddress = async () => {
    dispatch(clearAll({}))
    // Redirect to the edit profile page
    closeAlert()
    navigate('/users/profile?tab=edit-profile') // Perform the redirect
  }

  const closeAlert = () => {
    dispatch(setErrorMessage({ message: null }))
    dispatch(
      showAddressVerificationModalFunc({ modal: false, type: 'address' })
    )
  }

  return (
    <ModalLayout keepHeading={false}>
      <div className="modal-body">
        <h5 className="text-dark fw-bold">Please complete your address first</h5>
        {errorMsg && (
          <AuthAlert
            onClick={closeAlert}
            alertStyles={{ width: '95%', margin: '0 auto' }}
            status="error"
          >
            {errorMsg}
          </AuthAlert>
        )}
        <p style={{ fontSize: '1rem', lineHeight: '1.8rem' }}>
          Click the button below to update.
        </p>
        <div className="mt-3">
          {loading ? (
            <Spinner class_styles={'mb-1'} />
          ) : (
            <button
              onClick={updateAddress}
              className="btn btn-primary mb-1 me-2"
            >
              Update
            </button>
          )}
          <button type="button" class="btn btn-dark mb-1" data-dismiss="modal" onClick={closeAlert}>Close</button>

        </div>
      </div>
    </ModalLayout>
  )
}
