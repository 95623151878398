import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, clearResetBot, onGetProfileListings, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading, setOrderValues, showOrderModalFunc, setSalesValues, showSalesModalFunc } from '../../../../config/redux/slice/customerSlice'
import { onGetCountries, setAdminErrorMessageUser, setAdminLoadingUser, setDetails, showEventModalFunc } from '../../../../config/redux/slice/adminSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { Link, useNavigate } from 'react-router-dom'
import { get_delete_request } from '../../../../helpers/request'
import Pagination from "react-js-pagination";
import { useForm } from 'react-hook-form'
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

export default function ExpiredEvents({ types}) {
    const dispatch = useDispatch()
    const { register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: { errors }, reset } = useForm({ mode: 'onTouched' })
    const { token } = useSelector((state) => state.authData)
    const [active_data, getActive_data] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [addTrending, setAddTrending] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [eventDate, setEventDate] = useState(null);
    const [createdDate, setCreatedDate] = useState(null);
    useEffect(() => {
        getActiveList()
    }, [])
    const getActiveList = async () => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_events}?eventStatus=ExpiredEvents&page=1&pageSize=10&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        console.log("🚀 ~ getActiveList ~ res:", res)
        if (res !== undefined && res.status < 400) {
            let active_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal
            getActive_data(active_list);
            setRecordsTotal(totalCount)
            setSearchTerm('')
        }
        setLoading(false);
    }

    const handlePageChange = async (pageNumber) => {
        setLoading(true);
        setActivePage(pageNumber);
        dispatch(clearAll({}));
        const pageSize = 10;
        const formattedEventDate = eventDate ? formatDate(eventDate) : '';
        const formattedCreatedDate = createdDate ? formatDate(createdDate) : '';
        const queryParams = new URLSearchParams({
            eventStatus: 'ExpiredEvents',
            addTrending: addTrending ? 'true' : 'false',
            page: pageNumber,
            pageSize: pageSize,
            draw: 1,
            q: searchTerm,
        });

        if (formattedEventDate) {
            queryParams.set('date', formattedEventDate);
        }
        if (formattedCreatedDate) {
            queryParams.set('createdAt', formattedCreatedDate);
        }
        // Conditionally add the type if selected
        if (selectedType) {
            queryParams.append('type', selectedType);
        }
        const url = `${app_api.get_events}?${queryParams.toString()}`;
        const res = await get_delete_request(
            'get',
            token,
            url,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        if (res !== undefined && res.status < 400) {
            let active_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;
            getActive_data(active_list);
            setRecordsTotal(totalCount);
        }
        setLoading(false);
        
    };
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const formatDate = (date) => {
        return format(date, 'yyyy-MM-dd'); // Format as YYYY-MM-DD
    };
    const handleSearchChange = async (event) => {
        setLoading(true);
        dispatch(clearAll({}));
        const pageSize = 10;
        const formattedEventDate = eventDate ? formatDate(eventDate) : '';
        const formattedCreatedDate = createdDate ? formatDate(createdDate) : '';
        const queryParams = new URLSearchParams({
            eventStatus: 'ExpiredEvents',
            addTrending: addTrending ? 'true' : 'false',
            page: 1,
            pageSize: pageSize,
            draw: 1,
            q: searchTerm,
        });
        if (formattedEventDate) {
            queryParams.set('date', formattedEventDate);
        }
        if (formattedCreatedDate) {
            queryParams.set('createdAt', formattedCreatedDate);
        }
        // Conditionally add the type if selected
        if (selectedType) {
            queryParams.append('type', selectedType);
        }
        const url = `${app_api.get_events}?${queryParams.toString()}`;
        const res = await get_delete_request(
            'get',
            token,
            url,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        if (res !== undefined && res.status < 400) {
            let active_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal
            getActive_data(active_list);
            setRecordsTotal(totalCount)
        }
        setLoading(false);
        setActivePage(1);
    }
    const handleaddTrendingChange = () => {
        setAddTrending(!addTrending); // Toggle checkbox value
    }
    const resetForm = () => {
        reset();
        setSearchTerm('');
        setSelectedType('');
        setEventDate(null);
        setCreatedDate(null);
        setAddTrending(false);
        getActiveList();
    };
    return (
        <>
            <div className="row my-3">
                <div className="col-md-3">
                    <div className="form-group search-input-top">
                        <input autoComplete='off' type="text" className="form-control" id="" placeholder="Search..." value={searchTerm}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <select className="form-select" aria-label="" value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}>
                        <option value={''}>Select type</option>
                        {types.map((type, indx) => {
                            return <React.Fragment key={indx}>
                                {type.toLowerCase() != 'user' &&
                                    <option className='text-capitalize' key={indx} value={type}>{type}</option>
                                }
                            </React.Fragment>
                        })}
                    </select>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        <DatePicker
                            className="form-control"
                            // selected={date}
                            selected={eventDate}
                            onChange={setEventDate} //only when value has changed
                            placeholderText="Event Date"
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        {/* <input type="text" class="form-control" id="" placeholder="Event Date" /> */}
                        <DatePicker
                            className="form-control"
                            selected={createdDate}
                            onChange={setCreatedDate}
                            placeholderText="Created Date"
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                </div>
                <div className="col-md-1">
                    <div className="form-check" style={{ color: "#000" }}>
                        <input className="form-check-input" type="checkbox" value="" id="" checked={addTrending}
                            onChange={handleaddTrendingChange} />
                        <label className="form-check-label" for="">
                            Trending
                        </label>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="d-flex">
                        <button className="btn btn-primary" style={{ marginLeft: "10px" }}
                            onClick={handleSearchChange}
                        >Submit</button>
                        <button className="btn btn-primary" style={{ marginLeft: "10px", backgroundColor: "#0dcaf0", color: "#000", border: "none" }}
                            onClick={resetForm}
                        >Reset</button>
                    </div>
                </div>
            </div>
            <div className="table-responsive web-show-tbl">
                <table className="table table-striped mt-3 tbl-pending">
                    <thead>
                        <tr>
                            <th scope="col" className='no-wrap'>Event</th>
                            <th scope="col" className='no-wrap'>Description</th>
                            <th scope="col" className='no-wrap'>Venue</th>
                            <th scope="col" className='no-wrap'>Poster</th>
                            <th scope="col" className='no-wrap'>No of Listings</th>
                            <th scope="col" className='no-wrap'>No of Orders</th>
                            <th scope="col" className='no-wrap'>Event Type</th>
                            <th scope="col" className='no-wrap'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                            </tr>
                        ) : active_data.length > 0 ? (
                            active_data.map((item, index) => (
                                <>
                                    <tr key={index}>
                                    <td><div class="fw-bold">{item?.name}</div>
                                    <div>{moment(item?.date).format('ddd Do MMM YYYY - h:mma')}</div></td>
                                        <td>{item?.description}</td>
                                        <td>{item?.Venue.name}</td>
                                        <td> <img
                                            src={item?.poster}
                                            crossorigin="anonymous"
                                            width="80"
                                            height="80"
                                            alt="poster"
                                        /></td>
                                        <td>{item?.listingCount}</td>
                                        <td>{item?.orderCount}</td>
                                        <td>{item?.type}</td>
                                        <td>
                                            {item?.addTrending === true ?
                                                <div class="tag">Trending</div>
                                                : ""}
                                        </td>
                                    </tr>
                                </>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center py-4">No Data Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="mob-table-section">
                {loading ? (
                    <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>
                ) : active_data.length > 0 ? (
                    active_data.map((item, index) => (
                        <>
                            <div className="mob-table-sec-outer" key={index}>
                                <div className="position-relative">
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event</h3>
                                        <div className="mob-tbl-value">{item?.name}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Description</h3>
                                        <div className="mob-tbl-value" title={item?.description}>{item?.description}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Venue</h3>
                                        <div className="mob-tbl-value">{item?.Venue.name}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Poster</h3>
                                        <div className="mob-tbl-value"><img
                                            src={item?.poster}
                                            crossorigin="anonymous"
                                            width="80"
                                            height="80"
                                            alt="poster"
                                        /></div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">No of Listings</h3>
                                        <div className="mob-tbl-value">{item?.listingCount}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">No of Orders</h3>
                                        <div className="mob-tbl-value">{item?.orderCount}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event Type</h3>
                                        <div className="mob-tbl-value">{item?.type}</div>
                                    </div>
                                    <div className="mob-table-sec-expand">
                                        {item?.addTrending === true ?
                                            <div class="tag">Trending</div>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                ) : (
                    <div className="text-center py-4">No Data Found</div>
                )}
            </div>
            {recordsTotal > 0 ?
                <div className='pagination-wrapper'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={recordsTotal}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
                : ""}
        </>
    );
}
