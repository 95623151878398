import moment from 'moment'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearAll, onAddEvent, setAdminErrorMessageUser, setAdminLoadingUser, showAddEventFormFunc } from '../../../../config/redux/slice/adminSlice'
import { convertToDateTime, truncateMiddle } from '../../../../helpers/helpers'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'
import AddFormLayout from '../../../layout/admin/AddFormLayout'
import SelectComponent from '../../../components/Form/SelectComponent'
const formatTime = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};
const generateTimeSlots = () => {
  const slots = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      slots.push(`${formattedHour}:${formattedMinute}`);
    }
  }
  return slots;
};

export default function AddEvent({ types, usertypes,getActiveList }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: { errors }, reset } = useForm({ mode: 'onTouched' })
  const [formData, setFormData] = useState(new FormData());
  const [addTrending, setAddTrending] = useState(false); // State for checkbox
  const [selectedTime, setSelectedTime] = useState('00:00');
  const timeSlots = generateTimeSlots();

  const handleChange = (event) => {
    console.log('event', event.target.value);
    setSelectedTime(event.target.value);
  };

  const {
    adminLoading,
    adminErrorMsg,
    adminSuccessMsg
  } = useSelector((state) => state.admin)

  const {
    token
  } = useSelector((state) => state.authData)

  // const [types, setTypes] = useState([])

  const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];

  const typeValue = watch('type')

  const closeForm = () => {
    dispatch(showAddEventFormFunc({ modal: false }))
  }
  const handleaddTrendingChange = () => {
    setAddTrending(!addTrending); // Toggle checkbox value
  }
  // useEffect(()=>{
  //   getAllEventTypes()
  // },[])

  const onSubmit = async (data) => {
    dispatch(clearAll({}))

    const dateTime = convertToDateTime(data?.date, data?.time)
    const expiryBeforeInstantTicket = data?.expiryBeforeInstantTicket || '00:00';

    let name = data?.name;
    let description = data?.description;

    if (data?.type === "SPORT") {
      name = `${data?.homeTeam} vs ${data?.awayTeam}`; // Set event name to "Home Team vs Away Team"
      description = data?.competition; // Set description to the competition
    }

    data = {
      ...data, date: dateTime, addTrending, expiryBeforeInstantTicket, name,
      description
    }

    for (let key in data) {
      if (typeof data[key] == "object") {
        if (data[key].length != 0) {
          formData.append(key, data[key][0])
        }
      } else {
        formData.append(key, data[key])
      }
    }
    console.log('formData', formData);
    const res = await post_put_patch_request(
      "post",
      token,
      `${app_api.add_event}`,
      formData,
      dispatch,
      setAdminLoadingUser,
      onAddEvent,
      setAdminErrorMessageUser,
      true
    )

    if (res !== undefined && res.status < 400) {
      getActiveList();
      reset()
      resetFileInp()
      setFormData(new FormData())
      setAddTrending(false)
    } else {
      setFormData(new FormData())
      setAddTrending(false)
    }
  }

  // const getAllEventTypes = async () =>{
  //   dispatch(clearAll({}))
  //   const res = await get_delete_request(
  //     'get',
  //     token,
  //     `${app_api.get_event_type}`,
  //     {},
  //     dispatch,
  //     "",
  //     "",
  //     ""
  //   )
  //   if(res != undefined && res.status < 400){
  //     setTypes(res?.data?.data)
  //   }
  // }

  function addFileName(e, idValue) {
    let fileName = e.target.value;
    if (fileName !== '') {
      fileName = fileName.replace(/^.*[\\\/]/, '')
      document.getElementById(idValue).innerHTML = truncateMiddle(fileName, 30)

      const fileExtension = fileName.split('.').pop().toLowerCase();

      // Validate file extension
      if (!allowedExtensions.includes(fileExtension)) {
        alert('Only JPG, JPEG, PNG, and PDF files are allowed.'); // Inform user about invalid file type
        fileName = ''; // Reset file input to clear invalid selection
        document.getElementById(idValue).textContent = "No file chosen";
      }
    } else {
      document.getElementById(idValue).innerHTML = "No file Chosen"
    }
  }

  function resetFileInp() {
    document.querySelectorAll('.custom-file-label').forEach(() => {
      resetFileInp.innerHTML = 'Choose file...'
    })
  }

  return (
    <AddFormLayout onClick={closeForm} heading_title='Add New Event' sub_text='Fill in the fields below'>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="px-4 px-md-7">
          <div style={{ rowGap: '1.2rem' }} className="row mb-2">

            <div className='col-md-6 col-lg-4'>
              <div className="form-group">
                <label htmlFor='type'>Type <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  id="type"
                  {...register("type", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    }
                  })
                  }
                >
                  <option value={''}>Select type</option>
                  {types.map((type, indx) => {
                    return <React.Fragment key={indx}>
                      {type.toLowerCase() != 'user' &&
                        <option className='text-capitalize' key={indx} value={type}>{type}</option>
                      }
                    </React.Fragment>
                  })}
                </select>
                {
                  errors?.type?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.type?.message}
                  </div>
                }
              </div>
            </div>

            {typeValue !== 'SPORT' && typeValue !== '' && typeValue !== undefined &&

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='name'>Event <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className="form-control input-lg"
                    id="name"
                    {...register("name", {
                      required: {
                        value: true,
                        message: '*this field is required'
                      }
                    })
                    }
                  />
                  {
                    errors?.name?.message &&
                    <div className="text-danger small mt-1">
                      {errors?.name?.message}
                    </div>
                  }
                </div>
              </div>

            }

            {console.log("typeValuetypeValue", typeValue)}

            {typeValue !== 'SPORT' && typeValue !== '' && typeValue !== undefined &&

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='description'>Description <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className="form-control input-lg"
                    id="description"
                    {...register("description", {
                      required: {
                        value: true,
                        message: '*this field is required'
                      }
                    })
                    }
                  />
                  {
                    errors?.description?.message &&
                    <div className="text-danger small mt-1">
                      {errors?.description?.message}
                    </div>
                  }
                </div>
              </div>

            }

            {typeValue == 'SPORT' &&
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='competition'>Competition <span className="text-danger">*</span></label>

                  <SelectComponent
                    // searchValue={search_cities}
                    register={register}
                    isReq={true}
                    fieldName={'competitionId'}
                    fieldName2={'competition'}
                    text='Select Competition'
                    setValue={setValue}
                    setErrorFunc={setError}
                    getValueFunc={getValues}
                    errors={errors?.competition?.message}
                    clearErrorsFunc={clearErrors}
                    getEndpoint={app_api.get_competitions}
                    // isNotPaginated={true}
                    searchEndpoint={app_api.search_competition}
                  />
                </div>
              </div>
            }

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='venue'>Venue <span className="text-danger">*</span></label>

                <SelectComponent
                  // searchValue={search_cities}
                  register={register}
                  isReq={true}
                  fieldName={'venueId'}
                  fieldName2={'venue'}
                  text='Select Venue'
                  setValue={setValue}
                  setErrorFunc={setError}
                  getValueFunc={getValues}
                  errors={errors?.venue?.message}
                  clearErrorsFunc={clearErrors}
                  getEndpoint={app_api.get_venues}
                  // isNotPaginated={true}
                  searchEndpoint={app_api.search_venue}
                />
              </div>
            </div>
            {typeValue == 'SPORT' &&
              <>
                <div className="col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor='homeTeam'>Home Team <span className="text-danger">*</span></label>

                    <SelectComponent
                      // searchValue={search_cities}
                      register={register}
                      isReq={true}
                      fieldName={'homeTeamId'}
                      fieldName2={'homeTeam'}
                      text='Select Home Team'
                      setValue={setValue}
                      setErrorFunc={setError}
                      getValueFunc={getValues}
                      errors={errors?.homeTeam?.message}
                      clearErrorsFunc={clearErrors}
                      getEndpoint={app_api.get_teams}
                      // isNotPaginated={true}
                      searchEndpoint={app_api.search_team}
                    />
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor='awayTeam'>Away Team <span className="text-danger">*</span></label>

                    <SelectComponent
                      // searchValue={search_cities}
                      register={register}
                      isReq={true}
                      fieldName={'awayTeamId'}
                      fieldName2={'awayTeam'}
                      text='Select Away Team'
                      setValue={setValue}
                      setErrorFunc={setError}
                      getValueFunc={getValues}
                      errors={errors?.awayTeam?.message}
                      clearErrorsFunc={clearErrors}
                      getEndpoint={app_api.get_teams}
                      // isNotPaginated={true}
                      searchEndpoint={app_api.search_team}
                    />
                  </div>
                </div>
              </>}

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='commissionRate'>Commission Rate <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="commissionRate"
                  {...register("commissionRate", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    },
                    pattern: {
                      value: /^[0-9]+$/i,
                      message: '*invalid input'
                    },
                    max: {
                      value: 100,
                      message: `Maximum value exceeded`
                    },
                    min: {
                      value: 1,
                      message: `Minimum value exceeded`
                    }
                  })
                  }
                />
                {
                  errors?.commissionRate?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.commissionRate?.message}
                  </div>
                }
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-4">
              <div className='form-group'>
                <label htmlFor="poster">Poster <span className="text-danger">*</span></label>
                <div>
                  <div className="custom-file mb-1">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="poster"
                      accept=".jpg,.jpeg,.png,.pdf"
                      {...register("poster", {
                        required: {
                          value: true,
                          message: '*this field is required'
                        }
                      })
                      }
                      onChange={(e) => {
                        addFileName(e, 'custom-certificate')
                      }}

                    />
                    <label id="custom-certificate" className="custom-file-label">Choose file...</label>
                    {
                      errors?.poster?.message &&
                      <div className="text-danger small mt-1">
                        {errors?.poster?.message}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='date'>Date <span className="text-danger">*</span></label>
                <input
                  type="date"
                  min={moment().format('YYYY-MM-DD')}
                  className="form-control input-lg"
                  id="date"
                  {...register("date", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    }
                  })
                  }
                />
                {
                  errors?.date?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.date?.message}
                  </div>
                }
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='time'>Time <span className="text-danger">*</span></label>
                <input
                  type="time"
                  //   max={moment().format('YYYY-MM-DD')}
                  className="form-control input-lg"
                  id="time"
                  {...register("time", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    }
                  })
                  }
                />
                {
                  errors?.time?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.time?.message}
                  </div>
                }
              </div>
            </div>
            {/* xskr */}

            {/* xskr */}
            <div className='col-md-6 col-lg-4'>
              <div className="form-group">
                <label htmlFor='userType'>User Type <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  id="userType"
                  {...register("userType", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    }
                  })
                  }
                >
                  <option value={''}>Select User type</option>
                  {/* <option value={'ALL'}>ALL</option> */}
                  {usertypes.map((userType, indx) => {
                    return <React.Fragment key={indx}>
                      {userType.toLowerCase() != 'user' &&
                        <option className='text-capitalize' key={indx} value={userType}>{userType}</option>
                      }
                    </React.Fragment>
                  })}
                </select>
                {
                  errors?.userType?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.userType?.message}
                  </div>
                }
              </div>
            </div>

            <div className='col-md-6 col-lg-4'>
              <div className="form-group">
                <label htmlFor='expiry'>Expiry Before(Hours) <span className="text-danger">*</span></label>
                <select
                  className="form-control"
                  id="expiry"
                  {...register("expiry", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    }
                  })}
                >
                  <option value={''}>Select Hour </option>
                  {Array.from({ length: 24 }, (_, i) => i + 1).map((num) => (
                    <option key={num} value={num}>{num}</option>
                  ))}
                </select>
                {
                  errors?.expiry?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.expiry?.message}
                  </div>
                }
              </div>
            </div>
            {/* xskr */}

            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='time'>
                  Expiry Before For Instant Ticket <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="expiryBeforeInstantTicket"
                  value={selectedTime} // Set value to selectedTime state
                  {...register("expiryBeforeInstantTicket", {
                    required: {
                      value: false, // Change to true if you want this field to be required
                    }
                  })}
                  onChange={handleChange}
                >

                  {timeSlots.map((slot, index) => (
                    <option key={index} value={slot}>
                      {formatTime(slot)}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 d-flex align-items-center">
              <div className="form-group d-flex align-items-center">
                <input
                  type="checkbox"
                  id="addTrending"
                  checked={addTrending}
                  onChange={handleaddTrendingChange}
                />
                <label htmlFor="addTrending" className='mb-0 addTrending-labl'>Add Trending</label>

              </div>
            </div>
          </div>

        </div>
        <div className="mt-4 px-4 d-flex align-items-center">
          <div>
            {adminLoading ?
              <Spinner isForm={true} /> :
              <button type="submit" className="btn btn-primary me-3">Submit Form</button>
            }
          </div>
          <button disabled={adminLoading ? true : false} type="button" className="btn btn-info" onClick={closeForm}>Cancel</button>
        </div>
      </form>
    </AddFormLayout>
  )
}
