import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../components/Spinner'
import { truncateMiddle } from '../../../../helpers/helpers'
import Select from "react-select";

import {
  clearAll,
  onUpdate,
  setCustomerErrorMessage,
  setCustomerLoading,
} from '../../../../config/redux/slice/customerSlice'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { onGetUser } from '../../../../config/redux/slice/authSlice'
import PhoneNumberComponent from '../../../components/Form/PhoneNumberComponent'
import CountryComponent from '../../../components/Form/CountryComponent'

export default function EditProfile() {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onTouched' })
  const [formData, setFormData] = useState(new FormData())

  const {userData, token } = useSelector((state) => state.authData)
 
  const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
  const favouriteClubOptions = [
    { label: 'Arsenal', value: 'Arsenal' },
    { label: 'Aston Villa', value: 'Aston Villa' },
    { label: 'Bounemouth', value: 'Bounemouth' },
    { label: 'Brentford', value: 'Brentford' },
    { label: 'Brighton', value: 'Brighton' },
    { label: 'Chelsea', value: 'Chelsea' },
    { label: 'Crystal Palace', value: 'Crystal Palace' },
    { label: 'Everton', value: 'Everton' },
    { label: 'Fulham', value: 'Fulham' },
    { label: 'Ipswich Town', value: 'Ipswich Town' },
    { label: 'Leicester City', value: 'Leicester City' },
    { label: 'Liverpool', value: 'Liverpool' },
    { label: 'Manchester United', value: 'Manchester United' },
    { label: 'Newcastle', value: 'Newcastle' },
    { label: 'Nottingham Forest', value: 'Nottingham Forest' },
    { label: 'Southampton', value: 'Southampton' },
    { label: 'Tottenham Hotspurs', value: 'Tottenham Hotspurs' },
    { label: 'West Ham', value: 'West Ham' },
    { label: 'Wolves', value: 'Wolves' },
  ];

  const sportsInterestOptions = [
    { label: 'Rugby', value: 'Rugby' },
    { label: 'Cricket', value: 'Cricket' },
    { label: 'Tennis', value: 'Tennis' },
    { label: 'F1', value: 'F1' },
    { label: 'NFL', value: 'NFL' },
    { label: 'Basketball', value: 'Basketball' },
    { label: 'Concerts', value: 'Concerts' },
    { label: 'Theatre', value: 'Theatre' },
  ];

  useEffect(() => {
    const favouriteClubArray = userData?.favouriteClub
      ? userData.favouriteClub.split(',').map(club => ({
          label: club.trim(),
          value: club.trim(),
        }))
      : [];
  
    const favouriteSportsArray = userData?.favouriteSports
      ? userData.favouriteSports.split(',').map(sport => ({
          label: sport.trim(),
          value: sport.trim(),
        }))
      : [];

    setSelectedClub(favouriteClubArray);
    setSelectedSports(favouriteSportsArray);
  }, [userData]);

  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedSports, setSelectedSports] = useState(null);

  const handleClubSelection = (options) => {
    setSelectedClub(options);
  };

  const handleSportsSelection = (options) => {
    setSelectedSports(options);
  };
  const {
    customerLoading,
    customerSuccessMsg,
    listingManagement: { listingFormData },
    eventManagement: { eventDetails },
  } = useSelector((state) => state.customer)

  const onSubmit = async (data) => {
    dispatch(clearAll({}))
    // if (data.code && data.mobile_no) {
    //   data.phone = data.code + data.mobile_no
    // }

    let trimmedMobileNo = data.mobile_no;

    if (data.code && data.mobile_no) {
        // Escape the '+' character in the country code using a backslash
        const escapedCode = data.code.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // Escapes special characters
        const countryCodeRegex = new RegExp(`^${escapedCode}\\s?`);

        // Remove the country code from mobile_no if it starts with it
        trimmedMobileNo = data.mobile_no.replace(countryCodeRegex, '').trim();

        // Set the final phone number without duplicating the country code
        data.phone = data.code + ' ' + trimmedMobileNo;
    }

    if (data.mobile_no && data?.code == undefined) {
      setError('code', {
        type: 'manual',
        message: 'Please select country code',
      })
    } else if (!data.mobile_no && data?.code) {
      setError('code', {
        type: 'manual',
        message: 'Please enter phone number',
      })
    } else {
      for (let key in data) {
        if (typeof data[key] == 'object') {
          if (data[key].length != 0) {
            formData.append(key, data[key][0])
          }
        } else {
          if (data[key] && data[key] != '') {
            formData.append(key, data[key])
          }
        }
      }
      const favouriteClub = selectedClub && selectedClub.length > 0 ? selectedClub.map(val => val.value).join(', ') : null;
      const favouriteSports = selectedSports && selectedSports.length > 0 ? selectedSports.map(option => option.value).join(', ') : null;

      if (favouriteClub !== null) {
          formData.append('favouriteClub', favouriteClub);
      }
      
      if (favouriteSports !== null) {
          formData.append('favouriteSports', favouriteSports);
      }
     
      const res = await post_put_patch_request(
        'patch',
        token,
        `${app_api.update_user_info}`,
        formData,
        dispatch,
        setCustomerLoading,
        onGetUser,
        setCustomerErrorMessage,
        true
      )
      if (res !== undefined && res.status < 400) {
        dispatch(onUpdate({ message: res.data.message }))
        reset()
        resetFileInp()
        setFormData(new FormData())
       // setValue("countryId", data.countryId);
       // setValue("country", data.country);
      } else {
        setFormData(new FormData())
      }
    }
  }

  function addFileName(e, idValue) {
    let fileName = e.target.value
    if (fileName !== '') {
      fileName = fileName.replace(/^.*[\\\/]/, '')
      document.getElementById(idValue).innerHTML = truncateMiddle(fileName, 30)
      const fileExtension = fileName.split('.').pop().toLowerCase();

      // Validate file extension
      if (!allowedExtensions.includes(fileExtension)) {
        alert('Only JPG, JPEG, PNG, and PDF files are allowed.'); // Inform user about invalid file type
        fileName = ''; // Reset file input to clear invalid selection
        document.getElementById(idValue).textContent = "No file chosen";
      }
    } else {
      document.getElementById(idValue).innerHTML = 'No file Chosen'
    }
  }

  function resetFileInp() {
    document.querySelectorAll('.custom-file-label').forEach(() => {
      resetFileInp.innerHTML = 'Choose file...'
    })
  }

  const [localAddr1Error, setLocalAddr1Error] = useState('');
  const [localAddr2Error, setLocalAddr2Error] = useState('');
  const [localHouseNameError, setLocalHouseNameError] = useState('');
  const [localTownError, setLocalTownError] = useState('');
  const [localPostalCodeError, setLocalPostalCodeError] = useState('');

  const handleTextChange = (fieldName, value) => {
    if (fieldName === 'firstLineAddress') {
      if (value.length > 50) {
        setLocalAddr1Error('*Maximum length is 50 characters');
      } else {
        setLocalAddr1Error('');
      }
    } else if (fieldName === 'secondLineAddress') {
      if (value.length > 50) {
        setLocalAddr2Error('*Maximum length is 50 characters');
      } else {
        setLocalAddr2Error('');
      }
    } else if (fieldName === 'houseName') {
      if (value.length > 10) {
        setLocalHouseNameError('*Maximum length is 10');
      } else {
        setLocalHouseNameError('');
      }
    } else if (fieldName === 'town') {
      if (value.length > 20) {
        setLocalTownError('*Maximum length is 20');
      } else {
        setLocalTownError('');
      }
    } else if (fieldName === 'postCode') {
      if (value.length > 10) {
        setLocalPostalCodeError('*Maximum length is 10');
      } else {
        setLocalPostalCodeError('');
      }
    }
  };


  return (
    <div className="border-top py-3">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="px-4 px-md-7">
          <div style={{ rowGap: '1.2rem' }} className="row mb-2">
            <div className="col-xl-6">
              <div className="form-group">
                <label htmlFor="avatar">Avatar</label>
                <div>
                  <div className="custom-file mb-1">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="avatar"
                      accept=".jpg,.jpeg,.png,.pdf"
                      {...register('avatar')}
                      onChange={(e) => {
                        addFileName(e, 'custom-certificate')
                      }}
                    />
                    <label
                      id="custom-certificate"
                      className="custom-file-label"
                    >
                      Choose file...
                    </label>
                    {errors?.avatar?.message && (
                      <div className="text-danger small mt-1">
                        {errors?.avatar?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            

            <div className="col-xl-6">
              <div className="form-group">
                <PhoneNumberComponent
                  fieldName={'mobile_no'}
                  fieldName2={'code'}
                  error_msg={errors.mobile_new?.message ?? errors.code?.message}
                  label={'Phone Number'}
                  setValueFunc={setValue}
                  setErrorFunc={setError}
                  clearErrorsFunc={clearErrors}
                  isReq={false}
                  register={register}
                  defaultValue={userData?.phone}
                />
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group">
                <label htmlFor="firstLineAddress">First Line Address <span className="text-danger">*</span></label>
                <input
                defaultValue={userData?.firstLineAddress ?? ''}
                  type="text"
                  autoComplete='off'
                  className="form-control input-lg"
                  id="firstLineAddress"
                  {...register('firstLineAddress')}
                  onChange={(e) => handleTextChange('firstLineAddress', e.target.value)}
                />
                {localAddr1Error && <div className="text-danger small mt-1">{localAddr1Error}</div>}
                {errors?.firstLineAddress?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.firstLineAddress?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group">
                <label htmlFor="firstLineAddress">Second Line Address</label>
                <input
                  type="text"
                  autoComplete='off'
                  defaultValue={userData?.secondLineAddress ?? ''}
                  className="form-control input-lg"
                  id="secondLineAddress"
                  {...register('secondLineAddress')}
                  onChange={(e) => handleTextChange('secondLineAddress', e.target.value)}
                />
                {localAddr2Error && <div className="text-danger small mt-1">{localAddr2Error}</div>}
                {errors?.secondLineAddress?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.secondLineAddress?.message}
                  </div>
                )}
              </div>
            </div>

            {/* <div className="col-xl-6">
              <div className="form-group">
                <label htmlFor="houseName">House Number</label>
                <input
                  type="text"
                  defaultValue={userData?.houseName ?? ''}
                  className="form-control input-lg"
                  id="houseName"
                  {...register('houseName', {

                  })}
                  onChange={(e) => handleTextChange('houseName', e.target.value)}
                />
                {localHouseNameError && <div className="text-danger small mt-1">{localHouseNameError}</div>}
                {errors?.houseName?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.houseName?.message}
                  </div>
                )}
              </div>
            </div> */}

            <div className="col-xl-6">
              <div className="form-group">
                <label htmlFor="city">
                  Country <span className="text-danger">*</span>
                </label>

                <CountryComponent
                  // searchValue={search_cities}
                  register={register}
                  isReq={false}
                  fieldName={'countryId'}
                  fieldName2={'country'}
                  text="Select Country"
                  setValue={setValue}
                  setErrorFunc={setError}
                  getValueFunc={getValues}
                  errors={errors?.city?.message}
                  clearErrorsFunc={clearErrors}
                  getEndpoint={app_api.get_countries}
                  searchEndpoint={app_api.search_countries}
                  defaultValue={userData?.country} // Pass the default country here
                />
                {errors?.country?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.country?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group">
                <label htmlFor="town">City <span className="text-danger">*</span></label>
                <input
                  type="text"
                  autoComplete='off'
                  defaultValue={userData?.town ?? ''}
                  className="form-control input-lg"
                  id="town"
                  {...register('town')}
                  onChange={(e) => handleTextChange('town', e.target.value)}
                />
                {localTownError && <div className="text-danger small mt-1">{localTownError}</div>}
                {errors?.town?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.town?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group">
                <label htmlFor="postCode">Postal Code <span className="text-danger">*</span></label>
                <input
                  type="text"
                  autoComplete='off'
                  defaultValue={userData?.postCode ?? ''}
                  className="form-control input-lg"
                  id="postCode"
                  {...register('postCode', {

                  })}
                  onChange={(e) => handleTextChange('postCode', e.target.value)}
                />
                {localPostalCodeError && <div className="text-danger small mt-1">{localPostalCodeError}</div>}
                {errors?.postCode?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.postCode?.message}
                  </div>
                )}
              </div>
            </div>

            {!userData?.isSeller && (
              <>
               <div className="col-xl-6">
              <div className="form-group">
                <label htmlFor="postCode">Choose Favourite Teams</label>
                <Select
                isMulti
                id="FavouriteClub"
                options={favouriteClubOptions}
                value={selectedClub}
                {...register('favouriteClub')}
                onChange={handleClubSelection}
              />
              
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group">
                <label htmlFor="postCode"> Choose Sports/Interest</label>
                <Select
                isMulti
                id="SportsInterest"
                options={sportsInterestOptions}
                value={selectedSports}
                {...register('favouriteSports')}
                onChange={handleSportsSelection}
              />
              
              </div>
            </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-4 px-4 d-flex align-items-center">
          <div>
            {customerLoading ? (
              <Spinner isForm={true} />
            ) : (
              <button type="submit" className="btn btn-accent me-3">
                Save changes
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
