import { createSlice } from '@reduxjs/toolkit'
import { strText, system_user_roles } from '../../../constants/english'
import { buildAddress } from '../../../helpers/helpers'

const initialState = {
  loading: false,
  pageLoading: false,
  token: '',
  errorMsg: null,
  success_msg: null,
  userInfoExists: false,
  userData: {
    fullname: '',
    address: '',
    user_role: '',
    email: '',
    avatar: '',
    phone: '',
    phoneVerifiedAt: null,
    userType: '',
    houseName:'',
    firstLineAddress:'',
    secondLineAddress:'',
    town:'',
    postCode:'',
    country:'',
    avatar:'',
    favouriteClub: '',
    favouriteSports: '',
    isSeller: false
  },
  userTemporaryDetails: {
    email: '',
  },
  showPhoneNumberVerificationModal: {
    show: false,
    type: null,
    message: null,
  },
  showAddressVerificationModal: {
    show: false,
    type: null,
    message: null,
  },
}

export const authSlice = createSlice({
  name: 'authApp',
  initialState,
  reducers: {
    clearAll: (state, action) => {
      state.loading = false
      state.errorMsg = null
      state.success_msg = null
    },
    logoutUser: (state) => {
      localStorage.removeItem(`${strText.user_unique_id}`)
      state.loading = false
      state.token = ''
      state.pageLoading = false
      state.errorMsg = null
      state.success_msg = null
      state.userInfoExists = false
      state.userData = {
        fullname: '',
        address: '',
        user_role: '',
        email: '',
        avatar: '',
        phone: '',
        phoneVerifiedAt: null,
        userType: '',
        houseName:'',
        firstLineAddress:'',
        secondLineAddress:'',
        town:'',
        postCode:'',
        country:'',
        avatar:'',
        favouriteClub: '',
        favouriteSports: '',
        isSeller: false
      }
      state.userTemporaryDetails = {
        email: '',
      }
    },
    setLoading: (state, action) => {
      state.loading = action?.payload?.loading
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload.loading
    },

    setErrorMessage: (state, action) => {
      let msg = action.payload.message
      state.loading = false
      state.errorMsg = msg
    },
    setSuccessMessage: (state, action) => {
      let msg = action.payload.message
      state.loading = false
      state.success_msg = msg
    },
    onUserLogin: (state, action) => {
      let temp_token = action.payload.jwtToken
      localStorage.setItem(`${strText.user_unique_id}`, temp_token)
      state.token = temp_token
      state.loading = false
    },
    onGetUser: (state, action) => {
      let userDetails = action.payload
      const {
        houseName,
        firstLineAddress,
        secondLineAddress,
        town,
        postCode,
        country,
      } = userDetails
      state.loading = false
      state.userData = {
        fullname: userDetails?.name,
        address: buildAddress({
          houseName,
          firstLineAddress,
          secondLineAddress,
          town,
          postCode,
          country: country?.name,
        }),
        email: userDetails?.email,
        user_role: userDetails?.role,
        avatar: userDetails?.avatar,
        phone: userDetails?.phone,
        phoneVerifiedAt: userDetails?.phoneVerifiedAt,
        userType: userDetails?.userType, //XD_J
        id: userDetails.id,
        houseName:userDetails?.houseName,
        firstLineAddress:userDetails?.firstLineAddress,
        secondLineAddress:userDetails.secondLineAddress,
        town:userDetails.town,
        postCode:userDetails.postCode,
        //country:country?.name,
        country: userDetails?.countryId,
        avatar:userDetails.avatar,
        favouriteClub: userDetails.favouriteClub,
        favouriteSports: userDetails.favouriteSports,
        isSeller: userDetails.isSeller
      }
      if (userDetails.user_role)
        state.userData.user_role = userDetails.user_role
      state.userInfoExists = true
      if (
        userDetails?.phoneVerifiedAt == null &&
        system_user_roles.includes((userDetails?.role).toLowerCase() == false)
      ) {
        state.showPhoneNumberVerificationModal = {
          show: true,
          type: 'welcome',
          message: null,
        }
      }
    },
    onOnboardUser: (state, action) => {
      state.loading = false
      state.userTemporaryDetails = {
        ...state.userTemporaryDetails,
        ...action.payload,
      }
    },
    showPhoneNumberVerificationModalFunc: (state, action) => {
      state.showPhoneNumberVerificationModal = {
        show: action.payload.modal,
        type: action.payload.type,
        message: action?.payload?.message,
      }
    },
    showAddressVerificationModalFunc: (state, action) => {
      state.showAddressVerificationModal = {
        show: action.payload.modal,
        type: action.payload.type,
        message: action?.payload?.message,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  logoutUser,
  //
  setErrorMessage,
  setLoading,
  setPageLoading,
  onUserLogin,
  onGetUser,
  onOnboardUser,
  clearAll,
  showPhoneNumberVerificationModalFunc,
  showAddressVerificationModalFunc,
} = authSlice.actions

export default authSlice.reducer
